document.addEventListener("DOMContentLoaded", () => {
  const blocks = document.querySelectorAll(".block-slider .swiper");
  for (let i = 0; i < blocks.length; i += 1) {
    const delayvalue = blocks[i].dataset.autoplay;
    let autoplay = false;
    if (typeof delayvalue !== "undefined") {
      autoplay = {
        delay: delayvalue * 1000,
      };
    }
    const swiper = new Swiper(blocks[i], {// eslint-disable-line
      // Optional parameters
      loop: true,
      autoplay,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }
});
